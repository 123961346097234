import React, { useEffect, useState } from "react";
import "./pages.css";

import Table from "react-bootstrap/Table";
import { firebase } from "../firebase";
import { Button } from "react-bootstrap";

const ShareInformation = () => {
  const [url, setUrl] = useState();
  const [notice, setNotice] = useState();
  const [result, setResult] = useState();
  useEffect(() => {
    document.title = " Share Information | Star Adhesives";
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref("StarAdhesive/Prospectus/")
      .on("value", (snapshot) => {
        setUrl(snapshot.val());
      });
    firebase
      .database()
      .ref("StarAdhesive/Notice/")
      .on("value", (snapshot) => {
        setNotice(snapshot.val());
      });
    firebase
      .database()
      .ref("StarAdhesive/Result/")
      .on("value", (snapshot) => {
        setResult(snapshot.val());
      });
  }, []);

  return (
    <>
      <div className="aboutUs">
        <div class="bod ">
          <img src="assets/images/BOD.jpg" />
          <div className="titleText">
            <h2> Shared Information</h2>
          </div>
        </div>

        <div className="bodsec2">
          <div className="container fluid">
            <div className="row">
              <h2> Files</h2>
              <hr />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>File Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Vetted Prospectus </td>
                    <td style={{ padding: "16px" }}>
                      <a
                        className="ShareInfoLink"
                        href={url ? url.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Notice for QIs </td>
                    <td style={{ padding: "16px" }}>
                      <a
                        className="ShareInfoLink"
                        href={notice ? notice.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Allotment Result for SAL </td>
                    <td style={{ padding: "16px" }}>
                      <a
                        className="ShareInfoLink"
                        href={result ? result.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareInformation;
