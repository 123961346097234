import React, { useEffect } from "react";
import BodCard from "../components/BodCard";
import "./pages.css";

const Bod = () => {
  useEffect(() => {
    document.title = "Board of Directors | Star Adhesives";
  }, []);
  return (
    <>
      <div className="aboutUs">
        <div class="bod ">
          <img src="assets/images/BOD.jpg" />
          <div className="titleText">
            <h2>Board of Directors</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12">
            <BodCard
              src="assets/images/MD.jpg"
              name="Aziz Al Kaiser"
              designation="Chairman"
              info=" The Chairman, Aziz Al Kaiser is a successful industrialist with
              an appreciable acumen and a vision of great proportion. A caring
              parentage and an enterprising family upbringing helped him
              aquire his leadership skills and qualities from childhood.
              Beyond business collaborations, he takes profound interest in
              art, culture and sports. Having completed graduation from UK in
              Business Management, Kaiser joined his family business. Within a
              brief span of time, he helped lead the Group to a major spell of
              diversity that contributed to the Group’s surge forward with
              strong corporate governance. He also contributed towards the
              implementation of Group’s major investment from grassroots till
              completion of the projects and made Partex as a household name
              in the country. Currently Mr. Kaiser is leading 21 different
              companies and added more value to the group’s surge with more
              corporate stakes in banking, insurance, shipping and capital
              market."
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <BodCard
              src="assets/images/Picture (Amman Al Aziz).jpg"
              name="Amman Al Aziz"
              designation="Managing Director"
              info="
              The Managing Director, Amman Al Aziz is a young, enthusiastic and committed person
              started the journey with Partex since 2017 during the tenure of his Graduation. A disciplined
              parentage and an enterprising family upbringing helped him hone his leadership skills and
              qualities from childhood. Beyond business he takes profound interest in art, culture and sports.
              Having completed graduation from Brunel University and Masters in Banking &amp; Finance from
              Kings College, London, Amman joined his family business. Within a brief span of time, he is
              leading the Group to become the pioneer in the country and contributing to the Group’s surge
              forward with strong corporate governance. As a prudent member of the family, he is also
              accelerating the group business with the implementation of restructuring the diversified
              investment. Focusing on business transformation to adopt management strategies that embrace
              employee recognition, data driven innovation, process development, and national market
              development. Carrying forward the legacy of Partex Group from late M. A. Hashem, and current
              leader Mr. Aziz Al Kaiser – to embody their values as individuals throughout the company and
              it’s drive. 
                                "
            />
          </div>
        </div>

        <div className="row">
          {
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <BodCard
                src="assets/images/AmidAlAziz.jpg"
                name="Amid Al Aziz"
                designation="Nominee Director"
                info="Mr. Amid Al Aziz, Nominee Director, is a young, enthusiastic and committed person started
                the journey with Partex since 2018 along with his father Mr. Aziz Al Kaiser during the tenure of
                his Graduation. A caring parentage and an enterprising family upbringing helped him hone his
                leadership skills and qualities from childhood. Beyond business he takes profound interest in art,
                culture and sports. Having completed graduation from Bentley University, USA, Amid joined his
                family business. Within a brief span of time, he is leading the Group to become the pioneer in the
                country and contributing to the Group’s surge forward with strong corporate governance. As a
                prudent member of the family, focusing on Business transformation Mr. Amid is also looking for
                the diversified investment of the group business. "
              />
            </div>
          }

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <BodCard
              src="assets/images/Md. Nuruzzaman Chowdhury.jpg"
              name="Md. Nuruzzaman Chowdhury FCMA"
              designation="Nominee Director"
              info="Md. Nuruzzaman Chowdhury FCMA is a Fellow Cost &amp; Management
              Accountant. He is a seasoned finance executive with a track record of
              driving financial performance and fostering sustainable growth. He
              has more than 26 years of in-depth experience &amp; knowledge across
              all Corporate Finance, Accounting, Cost &amp; Budget, Treasury, Tax,
              Supply Chain Management and MIS Functions.
              
              He has attended in a good number of Training and CPD sessions arranged by the
              Institute of Cost and Management Accountants of Bangladesh (ICMAB). He has also
              attended lots of workshops, conferences and seminars on Strategic Financial
              Management, Decision-Making, Communication Skills, Leadership, Team Management,
              Team Building, Risk Management and Compliance. Academically he is Master of
              Commerce. He has vast working experience with working in different reputed
              organizations. He was the Chief Financial Officer of Trans Asia Industries Ltd (an
              enterprise of Uttara Group of Companies). He worked in Paramount Textile Ltd, S. Co
              Group of Companies, Bengal Telecommunication &amp; Electric Corporation.
              His diversified experience will help in contributing to the continued success and growth
              of Star Adhesive Limited."
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <BodCard
              src="assets/images/Nominee-1.png"
              name="A.K.M. Ahasanul Haque "
              designation="Nominee Director"
              info="Mr. A.K.M. Ahasanul Haque is Chief Operating Officer (COO) of Partex Cables Limited. He has been heading Partex Cables Limited since 2014.
                        Mr. Haque has over 26 years of progressive leadership experience in Electrical Cable, Automotive Battery and Plastic Industries. Prior to joining Partex Cables Limited, he was working as Executive Director in BBS Cables Limited. He also worked for other reputed local conglomerates – Beximco, Paradise, Navana, Pran-RFL Group. 
                        Mr. Ahasanul Haque Haque brings with him a wealth of leadership experience, especially in the areas of marketing, sales, project management, supply chain and production. He specializes in orchestrating large projects, start-ups, turnarounds and growth ventures. He is driven to provide strategic directions and improve the bottom line, while ensuring staff compliance to business policies and procedures."
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <BodCard
              src="assets/images/Nominee-2.png"
              name="Dr. Md. Nizamul Hoque Bhuiyan"
              designation="Independent Director"
              info="Dr. Md. Nizamul Hoque Bhuiyan is an Independent Director of the Company. He is a prominent academician, leader and Nutrition Science expert of Bangladesh. Dr. Bhuiyan is a professor in Institute of Nutrition and Food Science in the University of Dhaka. 
                        Apart from his teaching and research activities, Prof. Bhuiyan also successfully served in many administrative positions. He has enriched his experience attending different workshops and seminars arranges by national and International organizations at home and abroad. He is widely traveled person and led a number of Team/Group. Dr. Bhuiyan is an internationally recognized person in the Nutrition and Food Science sector having more than 29 years of experiences. "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Bod;
