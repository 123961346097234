import React from "react";
import NewsCard from "../components/NewsCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const NewsSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      arrows={false}
      containerClass="carousel-container"
    >
      <NewsCard
        date="May 4, 2024"
        name="Partex Inter-Company Cricket League 2024"
        src="assets/images/Partex Inter Company Cricket Tournament 2024.jpg"
        description={`The Partex family recently organized a thrilling cricket tournament, dubbed the "Partex Inter-Company Cricket League 2024," which captivated sports enthusiasts over four action-packed days. Commencing on May 1st, 2024`}
      />
      <NewsCard
        date="March 8, 2024"
        name="বিশ্ব নারী দিবসে পারটেক্স স্টার গ্রুপে অনুষ্ঠিত হয়ে গেল এক অনন্য সাংস্কৃতিক অনুষ্ঠান।"
        src="assets/images/Women’s Day 2024.jpg"
        description="বিশ্ব নারী দিবসে পারটেক্স স্টার গ্রুপে অনুষ্ঠিত হয়ে গেল এক অনন্য সাংস্কৃতিক অনুষ্ঠান। এই অনুষ্ঠানে পারটেক্স পরিবারের মেয়েদেরকে উৎসাহিত করার জন্য উপস্থিত ছিলেন পারটেক্স স্টার গ্রুপের মাননীয় চেয়ারপারসন সুলতানা হাসেম, মাননীয় ভাইস চেয়ারম্যান জনাব আজিজ আল কায়সার, ডিরেক্টর সাভেরা এইচ মাহমুদ এবং পারটেক্স গ্রুপের ডিরেক্টর সৈয়দা শেইরিন আজিজ। "
      />
      {/*  <NewsCard
        name="Event organized by partex group to boost employers"
        src="assets/images/Celebrating New Year 2024 2.jpeg"
        description="Star Adhesives is a sister concern of Partex group. On the verge of their grand launching Partex group organised a event."
      /> */}
      <NewsCard
        date="April 1, 2024"
        name="A Joyous New Year 2024 Celebration at Partex Star Group Corporate Office"
        src="assets/images/Celebrating New Year 2024.jpeg"
        description="New Year Celebration 2024 at corporate office. Reflecting on the milestones of the past year, the Partex Star Group family is inspired by the shared moments of triumph, creativity, and resilience. "
      />
      <NewsCard
        date="Jan 2024"
        name="Unveiling the Sweet Moments of Pitha Utsav Festival 2024 at Partex Star Group!"
        src="assets/images/Pitha Utshab 2024 1.jpeg"
        description="Excitement fills the air as the Partex Star Group gears up for the much-awaited Pitha Utsav festival of 2024. With a tradition deeply rooted in culture and heritage, this annual event promises to be a delightful celebration of flavors, traditions, and community spirit. "
      />

      <NewsCard
        date="April 2022"
        name="Trading Inauguration Ceremony of Star Adhesives Ltd at Dhaka Stock Exchange Ltd"
        src="assets/images/IMG_1370 (1).jpg"
        description="On April 20, 2022, Star Adhesives Ltd marked a significant milestone with its trading inauguration at the Dhaka Stock Exchange Ltd. The event was graced by prominent figures from the business community, including the Chairman of Partex Star Group, Mr. Aziz Al Kaiser, and the Managing Director of Star Adhesives Ltd, Mr. Amman Al Aziz."
      />
      <NewsCard
        date="April 2022"
        name="Trading Inauguration Ceremony of Star Adhesives Ltd at Dhaka Stock Exchange Ltd"
        src="assets/images/IMG_1310 (1).jpg"
        description="On April 20, 2022, Star Adhesives Ltd marked a significant milestone with its trading inauguration at the Dhaka Stock Exchange Ltd. The event was graced by prominent figures from the business community, including the Chairman of Partex Star Group, Mr. Aziz Al Kaiser, and the Managing Director of Star Adhesives Ltd, Mr. Amman Al Aziz."
      />
    </Carousel>
  );
};

export default NewsSlider;
