import React, { useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import Form from "react-bootstrap/Form";

import "./Product.css";

const StarBondNcThinner = () => {
  const [show50, setShow50] = useState(true);
  const [show5, setShow5] = useState(false);
  const [show20, setShow20] = useState(false);
  const [show, setShow] = useState(false);

  const [imageLink, setimageLink] = useState("assets/products/NcThinner/1.png");

  const [Boisistho, setBoisistho] = useState(true);
  const [sotorkota, setsotorkota] = useState(false);

  var btns = document.getElementsByClassName("btn2");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function () {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active");
      this.className += " active";
    });
  }

  const changeDropdown = (value) => {
    if (value == "50g") {
      setShow50(true);
      setShow5(false);
      setShow20(false);
      setimageLink("assets/products/NcThinner/1.png");
    } else if (value == "125g") {
      setShow50(false);
      setShow5(true);
      setShow20(false);
      setimageLink("assets/products/NcThinner/2.png");
    }
  };
  return (
    <>
      <Container className="productDetailContainer">
        <Row>
          <Col className="ProductImageContainer">
            <h4>Star Bond NC Thinner</h4>
            {show50 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/products/NcThinner/1.png"
                    onClick={() => {
                      setimageLink("assets/products/NcThinner/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/products/NcThinner/2.png"
                    onClick={() => {
                      setimageLink("assets/products/NcThinner/2.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/NcThinner/3.png"
                    onClick={() => {
                      setimageLink("assets/products/NcThinner/3.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show5 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/5 kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/images/product/NcThinner/1.png"
                    onClick={() => {
                      setimageLink("assets/images/product/NcThinner/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/images/product/NcThinner/2.png"
                    onClick={() => {
                      setimageLink("assets/images/product/NcThinner/2.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show20 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: "assets/images/product/10 Kg.png",
                      },
                      largeImage: {
                        src: "assets/images/product/10 Kg.png",
                        width: 960,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/10 Kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img className="" src="assets/images/product/B-58.png" />
                  <img
                    className=""
                    src="assets/images/product/20kg-large.png"
                  />
                  <img
                    className=""
                    src="assets/images/product/10 kg-large.png"
                  />
                </div>
              </>
            ) : (
              " "
            )}
          </Col>
          <Col>
            <div className="productInfo">
              <h3>
                <b>USES</b>
              </h3>
              <p>
                Star Bond NC Thinner can be used in all types of lacquer paints,
                car paints, melamine finishes and leather finishes. It can also
                be used in industrial, commercial and home furnishings. How much
                thinner is added to make the color dilute or suitable for use
                depends on the color and the way the user uses it (although it
                is better not to make the color much dilute). The surface should
                be moisturized and cleaned before use and then painted.
              </p>

              <h3>
                <b>SIZE</b>
              </h3>
              <Form.Select
                size="lg"
                id="choices"
                onChange={(e) => {
                  changeDropdown(e.target.value);
                }}
              >
                <option value="50g">1 Ltr</option>
                <option value="50g">3.78 Ltr</option>
                <option value="50g">20 Ltr</option>
                <option value="50g">200 Ltr</option>
              </Form.Select>
            </div>

            <div className="CallSection">
              <p>
                For any quarries or Product related information, please call{" "}
                <a href="tel:+8801313012575">+8801313012575</a>. You can contact
                us through (WhatsApp icon) or (messenger icon) as well.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div>
          <div className="headingSection">
            <p
              className="btn2 active"
              onClick={() => {
                setBoisistho(true);
                setsotorkota(false);
              }}
            >
              Characteristics
            </p>
            <p
              className="btn2"
              onClick={() => {
                setBoisistho(false);
                setsotorkota(true);
              }}
            >
              Caution
            </p>
            <hr />
          </div>

          {Boisistho ? (
            <div className="SectionDescription Boisistho">
              <ul>
                <li>
                  Excellent quality thinner with quality binding standard.
                </li>
                <li>
                  It is suitable for all types of surfaces and has a nice finish
                  in the coating.
                </li>
                <li>Dries quickly after planting</li>
                <li>Brings beauty to the lacquer and enhances durability.</li>
                <li>No acute or intense odor</li>
              </ul>
            </div>
          ) : (
            " "
          )}
          {sotorkota ? (
            <div className="SectionDescription Sotorkota">
              <ul>
                <li>Keep away from fire</li>
                <li>Keep out of reach of children</li>
                <li>Do not use in closed environment</li>
              </ul>
            </div>
          ) : (
            " "
          )}
        </div>
      </Container>
    </>
  );
};

export default StarBondNcThinner;
