import "./App.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import Header from "./home/Header";
import MainPage from "./home/MainPage";
import Footer from "./home/Footer";
import AboutUs from "./pages/AboutUs";
import Bod from "./pages/Bod";
import Management from "./pages/Management";
import Contact from "./pages/Contact";
import Chairman from "./pages/Chairman";
import Md from "./pages/Md";
import UnderConstruction from "./pages/UnderConstruction";
import Prospectus from "./admin/Prospectus";
import SpeficifProduct from "./product/SpeficifProduct";
import StarBondSyntheticResinAdhesives from "./product/StarBondSyntheticResinAdhesives";
import StarBondRubberAdhesives from "./product/StarBondRubberAdhesives";
import StarBondFixGelTube from "./product/StarBondFixGelTube";
import SyntheticDistemperAdhesives from "./product/SyntheticDistemperAdhesives";
import StarBondSuperGlue from "./product/StarBondSuperGlue";
import StarBondPurAdhesive from "./product/StarBondPurAdhesive";
import StarLaqPuHardner from "./product/StarLaqPuHardner";
import GumkingSyntheticResin from "./product/GumkingSyntheticResin";
import GumkingSyntheticRubber from "./product/GumkingSyntheticRubber";
import StarBondSB40 from "./product/StarBondSB40";
import StarBondDashboard from "./product/StarBondDashboard";
import StarBondHotMelt from "./product/StarBondHotMelt";
import StarBondNcThinner from "./product/StarBondNcThinner";
import StarBondSilica from "./product/StarBondSilica";
import StarBondUpvcSolvent from "./product/StarBondUpvcSolvent";
import StarBondWood from "./product/StarBondWood";
import StarBondSprayPaint from "./product/StarBondSprayPaint";
import PriceSensitiveInformation from "./pages/PriceSensitiveInformation";
import AnnualReport from "./pages/AnnualReport";
import FinancialReport from "./pages/FinancialReport";
import UploadFiles from "./admin/UploadFiles";
import AllNotice from "./pages/AllNotice";
import ShareInformation from "./pages/ShareInformation";
import BondIssue from "./pages/BondIssue";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
          <Route path="/Bod">
            <Bod />
          </Route>
          <Route path="/management">
            <Management />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/chairman">
            <Chairman />
          </Route>
          <Route path="/MD">
            <Md />
          </Route>
          <Route path="/underConstruction">
            <UnderConstruction />
          </Route>
          <Route path="/prospectus">
            <Prospectus />
          </Route>
          <Route path="/SyntheticResinAdhesive">
            <StarBondSyntheticResinAdhesives />
          </Route>
          <Route path="/SyntheticRubberAdhesive">
            <StarBondRubberAdhesives />
          </Route>
          <Route path="/FixGelTube">
            <StarBondFixGelTube />
          </Route>
          <Route path="/SyntheticDistemberAdhesives">
            <SyntheticDistemperAdhesives />
          </Route>
          <Route path="/SuperGlue">
            <StarBondSuperGlue />
          </Route>
          <Route path="/PurAdhesives">
            <StarBondPurAdhesive />
          </Route>
          <Route path="/PuHardener">
            <StarLaqPuHardner />
          </Route>
          <Route path="/GumkingSyntheticResin">
            <GumkingSyntheticResin />
          </Route>
          <Route path="/GumkingSyntheticRubber">
            <GumkingSyntheticRubber />
          </Route>
          <Route path="/StarBondSB40">
            <StarBondSB40 />
          </Route>
          <Route path="/StarBondDashboardCleaner">
            <StarBondDashboard />
          </Route>
          <Route path="/StarBondHotMeltAdhesives">
            <StarBondHotMelt />
          </Route>
          <Route path="/StarBondNCThinner">
            <StarBondNcThinner />
          </Route>
          <Route path="/StarBondSilica">
            <StarBondSilica />
          </Route>
          <Route path="/UPVCSolventCement">
            <StarBondUpvcSolvent />
          </Route>
          <Route path="/StarBondWoodPreservative">
            <StarBondWood />
          </Route>
          <Route path="/StarBondSprayPaint">
            <StarBondSprayPaint />
          </Route>
          <Route path="/PriceSensitiveInformation">
            <PriceSensitiveInformation />
          </Route>
          <Route path="/AnnualReport">
            <AnnualReport />
          </Route>
          <Route path="/FinancialReport">
            <FinancialReport />
          </Route>
          <Route path="/UploadFiles">
            <UploadFiles />
          </Route>
          <Route path="/AllNotice">
            <AllNotice />
          </Route>
          <Route path="/SharedInformation">
            <ShareInformation />
          </Route>
          <Route path="/BondIssue">
            <BondIssue />
          </Route>
        </Switch>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
