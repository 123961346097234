import React, { useEffect, useState } from "react";
import "./pages.css";

import Table from "react-bootstrap/Table";
import { firebase } from "../firebase";
import { Button } from "react-bootstrap";
const BondIssue = () => {
  useEffect(() => {
    document.title = " Bond Issue | Star Adhesives";
  }, []);
  const [tableData, setTableData] = useState({});
  useEffect(() => {
    firebase
      .database()
      .ref("StarAdhesive")
      .child("UploadFiles")
      .child("BondIssue")
      .on("value", (snapshot) => {
        if (snapshot.val() != null) {
          setTableData({ ...snapshot.val() });
        }
      });
  }, []);
  return (
    <>
      <div className="aboutUs">
        <div class="bod ">
          <img src="assets/images/BOD.jpg" />
          <div className="titleText">
            <h2> Bond Issue</h2>
          </div>
        </div>
      </div>

      <div className="bodsec2">
        <div className="container fluid">
          <div className="row">
            <h2>Bond Issue</h2>
            <hr />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sl.</th>
                  <th>File Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(tableData).map((id, index) => {
                  return (
                    <tr key={id}>
                      <td>{index + 1}</td>
                      <td>{tableData[id].FileName}</td>
                      <td>
                        <Button
                          href={tableData[id].DownloadUrl}
                          variant="primary"
                          size="md"
                          target="_blank"
                        >
                          Download
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BondIssue;
