import React, { useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import Form from "react-bootstrap/Form";

import "./Product.css";

const StarBondSprayPaint = () => {
  const [show50, setShow50] = useState(true);
  const [show5, setShow5] = useState(false);
  const [show20, setShow20] = useState(false);
  const [show, setShow] = useState(false);

  const [imageLink, setimageLink] = useState(
    "assets/products/SprayPaint/1.png"
  );

  const [Boisistho, setBoisistho] = useState(true);
  const [sotorkota, setsotorkota] = useState(false);

  var btns = document.getElementsByClassName("btn2");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function () {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active");
      this.className += " active";
    });
  }

  const changeDropdown = (value) => {
    if (value == "50g") {
      setShow50(true);
      setShow5(false);
      setShow20(false);
      setimageLink("assets/products/SprayPaint/1.png");
    } else if (value == "125g") {
      setShow50(false);
      setShow5(true);
      setShow20(false);
      setimageLink("assets/products/SprayPaint/2.png");
    }
  };
  return (
    <>
      <Container className="productDetailContainer">
        <Row>
          <Col className="ProductImageContainer">
            <h4>Star Bond Spray Paint</h4>
            {show50 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/products/SprayPaint/1.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/products/SprayPaint/2.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/2.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/3.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/3.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/4.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/4.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/5.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/5.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/6.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/6.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/7.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/7.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/8.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/8.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/SprayPaint/9.png"
                    onClick={() => {
                      setimageLink("assets/products/SprayPaint/9.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show5 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/5 kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/images/product/SprayPaint/1.png"
                    onClick={() => {
                      setimageLink("assets/images/product/SprayPaint/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/images/product/SprayPaint/2.png"
                    onClick={() => {
                      setimageLink("assets/images/product/SprayPaint/2.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show20 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: "assets/images/product/10 Kg.png",
                      },
                      largeImage: {
                        src: "assets/images/product/10 Kg.png",
                        width: 960,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/10 Kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img className="" src="assets/images/product/B-58.png" />
                  <img
                    className=""
                    src="assets/images/product/20kg-large.png"
                  />
                  <img
                    className=""
                    src="assets/images/product/10 kg-large.png"
                  />
                </div>
              </>
            ) : (
              " "
            )}
          </Col>
          <Col>
            <div className="productInfo">
              <h3>
                <b>USES</b>
              </h3>
              <p>
                Star Bond Spray Paint is unique for painting any interior and
                exterior work in a very short time, such as Cars, CNG,
                Motorcycles, Kitchen Racks, Wooden Walls, etc. Star Bond Spray
                Paint should be applied after cleaning the surface properly. If
                you want to paint any object with Star Bond Spray Paint, the
                distance between the spray bottle and the painted object should
                be 8-12 inches.
              </p>
              <h4>Color</h4>
              <p>
                Metallic Color Paint Gold, Aluminum Silver, Chrome (SS Color)
                400cc, Aerosol Paint (Acrylic) White, Aerosol Paint (Acrylic)
                Black, Aerosol Paint (Acrylic) Silver Gray, Aerosol Paint
                (Acrylic) Red, Flat Black, Aerosol Paint (Acrylic) Clear,
                Aerosol Paint (Acrylic) Apple Green, Aerosol Paint (Acrylic)
                Candy Red (Honda Red), Aerosol Paint (Acrylic) Pink, Aerosol
                Paint (Acrylic) Cocoa Brown, Flat White, Aerosol Paint (Acrylic)
                Bright Silver, Aerosol Paint (Acrylic) Blue, Aerosol Paint
                (Acrylic) Yellow, Aerosol Paint (Acrylic) Green, River Blue, Off
                White, Mahogany, Violet, Orange, Blue-21
              </p>
              <h3>
                <b>Size</b>
              </h3>
              <Form.Select
                size="lg"
                id="choices"
                onChange={(e) => {
                  changeDropdown(e.target.value);
                }}
              >
                <option value="50g">400 ml</option>
              </Form.Select>
            </div>

            <div className="CallSection">
              <p>
                For any quarries or Product related information, please call{" "}
                <a href="tel:+8801313012575">+8801313012575</a>. You can contact
                us through (WhatsApp icon) or (messenger icon) as well.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div>
          <div className="headingSection">
            <p
              className="btn2 active"
              onClick={() => {
                setBoisistho(true);
                setsotorkota(false);
              }}
            >
              Characteristics
            </p>
            <p
              className="btn2"
              onClick={() => {
                setBoisistho(false);
                setsotorkota(true);
              }}
            >
              Caution
            </p>
            <hr />
          </div>

          {Boisistho ? (
            <div className="SectionDescription Boisistho">
              <ul>
                <li>Dries quickly</li>
                <li>More coverage.</li>
                <li>More brightness and durable color.</li>
                <li>Huge combination of different types of colors</li>
              </ul>
            </div>
          ) : (
            " "
          )}
          {sotorkota ? (
            <div className="SectionDescription Sotorkota">
              <ul>
                <li>Keep out of reach of children</li>
                <li>It is strictly forbidden to keep it at high temperature</li>
                <li>
                  Work should be done in places where there is adequate light
                  and air
                </li>
                <li>Ensure eye and skin protection</li>
              </ul>
            </div>
          ) : (
            " "
          )}
        </div>
      </Container>
    </>
  );
};

export default StarBondSprayPaint;
