import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { firebase } from "../firebase";

const UploadFiles = () => {
  useEffect(() => {
    document.title = " Upload Files | Star Adhesives";
  }, []);

  const [priceInformation, setpriceInformation] = useState();
  const [annualReport, setannualReport] = useState();
  const [allNotice, setAllNotice] = useState();
  const [financialStatement, setfinancialStatement] = useState();
  const [bondIssue, setbondIssue] = useState();

  const PriceInfoUpload = () => {
    var UploadTime = new Date().toLocaleDateString();
    var filetitle = priceInformation.name.slice(0, -4);
    var fileext = priceInformation.name.slice(-4);

    var FullName = filetitle + " -" + UploadTime.toString() + fileext;

    const storageRef = firebase.storage().ref(priceInformation.name);
    storageRef.put(priceInformation).on(
      "state_changed",
      (snap) => {},
      (error) => {
        console.log(error);
      },
      async () => {
        const url = await storageRef.getDownloadURL();

        firebase
          .database()
          .ref("StarAdhesive")
          .child("UploadFiles")
          .child("PriceSensitiveInformation")
          .push({
            FileName: FullName,
            DownloadUrl: url,
          })
          .then(() => {
            console.log("Success");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  const AnnualReportUpload = () => {
    var UploadTime = new Date().toLocaleDateString();
    var filetitle = annualReport.name.slice(0, -4);
    var fileext = annualReport.name.slice(-4);

    var FullName = filetitle + " -" + UploadTime.toString() + fileext;

    const storageRef = firebase.storage().ref(annualReport.name);
    storageRef.put(annualReport).on(
      "state_changed",
      (snap) => {},
      (error) => {
        console.log(error);
      },
      async () => {
        const url = await storageRef.getDownloadURL();

        firebase
          .database()
          .ref("StarAdhesive")
          .child("UploadFiles")
          .child("AnnualReport")
          .push({
            FileName: FullName,
            DownloadUrl: url,
          })
          .then(() => {
            console.log("Success");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  const AllNoticeUpload = () => {
    var UploadTime = new Date().toLocaleDateString();
    var filetitle = allNotice.name.slice(0, -4);
    var fileext = allNotice.name.slice(-4);

    var FullName = filetitle + " -" + UploadTime.toString() + fileext;

    const storageRef = firebase.storage().ref(allNotice.name);
    storageRef.put(allNotice).on(
      "state_changed",
      (snap) => {},
      (error) => {
        console.log(error);
      },
      async () => {
        const url = await storageRef.getDownloadURL();

        firebase
          .database()
          .ref("StarAdhesive")
          .child("UploadFiles")
          .child("AllNotice")
          .push({
            FileName: FullName,
            DownloadUrl: url,
          })
          .then(() => {
            console.log("Success");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  const FinancialInfoUpload = () => {
    var UploadTime = new Date().toLocaleDateString();
    var filetitle = financialStatement.name.slice(0, -4);
    var fileext = financialStatement.name.slice(-4);

    var FullName = filetitle + " -" + UploadTime.toString() + fileext;

    const storageRef = firebase.storage().ref(financialStatement.name);
    storageRef.put(financialStatement).on(
      "state_changed",
      (snap) => {},
      (error) => {
        console.log(error);
      },
      async () => {
        const url = await storageRef.getDownloadURL();

        firebase
          .database()
          .ref("StarAdhesive")
          .child("UploadFiles")
          .child("FinancialStatement")
          .push({
            FileName: FullName,
            DownloadUrl: url,
          })
          .then(() => {
            console.log("Success");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  const BondissueUpload = () => {
    var UploadTime = new Date().toLocaleDateString();
    var filetitle = bondIssue.name.slice(0, -4);
    var fileext = bondIssue.name.slice(-4);

    var FullName = filetitle + " -" + UploadTime.toString() + fileext;

    const storageRef = firebase.storage().ref(bondIssue.name);
    storageRef.put(bondIssue).on(
      "state_changed",
      (snap) => {},
      (error) => {
        console.log(error);
      },
      async () => {
        const url = await storageRef.getDownloadURL();

        firebase
          .database()
          .ref("StarAdhesive")
          .child("UploadFiles")
          .child("BondIssue")
          .push({
            FileName: FullName,
            DownloadUrl: url,
          })
          .then(() => {
            console.log("Success");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  return (
    <>
      <div className="aboutUs">
        <div class="bod ">
          <img src="assets/images/BOD.jpg" />
          <div className="titleText">
            <h2> Upload Files</h2>
          </div>
        </div>
      </div>

      <div className="bodsec2">
        <div className="container fluid">
          <div className="row">
            <h2>Upload Price Sensitive Information</h2>
            <hr />
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                PriceInfoUpload();
              }}
            >
              <Form.Group controlId="formFileLg" className="mb-3">
                <Form.Control
                  type="file"
                  size="lg"
                  value={undefined}
                  onChange={(e) => setpriceInformation(e.target.files[0])}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>

          <div className="row" style={{ marginTop: "50px" }}>
            <h2>Upload Annual Report</h2>
            <hr />
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                AnnualReportUpload();
              }}
            >
              <Form.Group controlId="formFileLg" className="mb-3">
                <Form.Control
                  type="file"
                  size="lg"
                  value={undefined}
                  onChange={(e) => setannualReport(e.target.files[0])}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <h2>Upload Financial Statement</h2>
            <hr />
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                FinancialInfoUpload();
              }}
            >
              <Form.Group controlId="formFileLg" className="mb-3">
                <Form.Control
                  type="file"
                  size="lg"
                  value={undefined}
                  onChange={(e) => setfinancialStatement(e.target.files[0])}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>

          <div className="row" style={{ marginTop: "50px" }}>
            <h2>Upload Notice</h2>
            <hr />
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                AllNoticeUpload();
              }}
            >
              <Form.Group controlId="formFileLg" className="mb-3">
                <Form.Control
                  type="file"
                  size="lg"
                  value={undefined}
                  onChange={(e) => setAllNotice(e.target.files[0])}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>

          <div className="row" style={{ marginTop: "50px" }}>
            <h2>Upload BOND ISSUE</h2>
            <hr />
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                BondissueUpload();
              }}
            >
              <Form.Group controlId="formFileLg" className="mb-3">
                <Form.Control
                  type="file"
                  size="lg"
                  value={undefined}
                  onChange={(e) => setbondIssue(e.target.files[0])}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFiles;
