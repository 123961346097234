import React, { useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import Form from "react-bootstrap/Form";

import "./Product.css";

const StarBondHotMelt = () => {
  const [show50, setShow50] = useState(true);
  const [show5, setShow5] = useState(false);
  const [show20, setShow20] = useState(false);
  const [show, setShow] = useState(false);

  const [imageLink, setimageLink] = useState("assets/products/Hotmelt/1.png");

  const [Boisistho, setBoisistho] = useState(true);
  const [sotorkota, setsotorkota] = useState(false);

  var btns = document.getElementsByClassName("btn2");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function () {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active");
      this.className += " active";
    });
  }

  const changeDropdown = (value) => {
    if (value == "50g") {
      setShow50(true);
      setShow5(false);
      setShow20(false);
      setimageLink("assets/products/Hotmelt/1.png");
    } else if (value == "125g") {
      setShow50(false);
      setShow5(true);
      setShow20(false);
      setimageLink("assets/products/Hotmelt/2.png");
    }
  };
  return (
    <>
      <Container className="productDetailContainer">
        <Row>
          <Col className="ProductImageContainer">
            <h4>Star Bond Hot Melt Adhesives</h4>
            {show50 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/products/Hotmelt/1.png"
                    onClick={() => {
                      setimageLink("assets/products/Hotmelt/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/products/Hotmelt/2.png"
                    onClick={() => {
                      setimageLink("assets/products/Hotmelt/2.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/Hotmelt/3.png"
                    onClick={() => {
                      setimageLink("assets/products/Hotmelt/3.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show5 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/5 kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/images/product/Hotmelt/1.png"
                    onClick={() => {
                      setimageLink("assets/images/product/Hotmelt/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/images/product/Hotmelt/2.png"
                    onClick={() => {
                      setimageLink("assets/images/product/Hotmelt/2.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show20 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: "assets/images/product/10 Kg.png",
                      },
                      largeImage: {
                        src: "assets/images/product/10 Kg.png",
                        width: 960,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/10 Kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img className="" src="assets/images/product/B-58.png" />
                  <img
                    className=""
                    src="assets/images/product/20kg-large.png"
                  />
                  <img
                    className=""
                    src="assets/images/product/10 kg-large.png"
                  />
                </div>
              </>
            ) : (
              " "
            )}
          </Col>
          <Col>
            <div className="productInfo">
              <h3>
                <b>USES</b>
              </h3>
              <p>
                Keep the material clean. Always clean the adhesives setting box.
                When cleaning, control the box below 150 degrees Celsius. Remove
                the remaining adhesive before applying the new glue. Turn off
                the machine after using with glue tank or glue gun.
              </p>

              <h3>
                <b>SIZE</b>
              </h3>
              <Form.Select
                size="lg"
                id="choices"
                onChange={(e) => {
                  changeDropdown(e.target.value);
                }}
              >
                <option value="50g">25 kg</option>
              </Form.Select>
            </div>

            <div className="CallSection">
              <p>
                For any quarries or Product related information, please call{" "}
                <a href="tel:+8801313012575">+8801313012575</a>. You can contact
                us through (WhatsApp icon) or (messenger icon) as well.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div>
          <div className="headingSection">
            <p
              className="btn2 active"
              onClick={() => {
                setBoisistho(true);
                setsotorkota(false);
              }}
            >
              Characteristics
            </p>
            <p
              className="btn2"
              onClick={() => {
                setBoisistho(false);
                setsotorkota(true);
              }}
            >
              Caution
            </p>
            <hr />
          </div>

          {Boisistho ? (
            <div className="SectionDescription Boisistho">
              <ul>
                <li>
                  Book binding task can be set up nicely in a very short time by
                  using this product with the help of machines. This product,
                  which looks like white granules, contains a substance called E
                  V A Resin. It helps to bind in 4-5 seconds at a temperature of
                  160-180 degrees Celsius. Also suitable for use in packaging,
                  diapers, level, tape, sticker etc.
                </li>
              </ul>
            </div>
          ) : (
            " "
          )}
          {sotorkota ? (
            <div className="SectionDescription Sotorkota">
              <ul>
                <li>Cannot be melted at temperatures above 180 degrees.</li>
              </ul>
            </div>
          ) : (
            " "
          )}
        </div>
      </Container>
    </>
  );
};

export default StarBondHotMelt;
