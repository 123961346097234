import React, { useEffect } from "react";
import "./pages.css";

const Management = () => {
  useEffect(() => {
    document.title = "Management Team | Star Adhesives";
  }, []);
  return (
    <div>
      <div className="aboutUs">
        <div className="management">
          <img src="assets/images/Management.jpg" />
          <div className="titleText">
            <h2>Management Team</h2>
          </div>
        </div>
      </div>

      <div className="DirectorPanel">
        <div className="container">
          {/* <h3 style={{ paddingTop: '50px' }}>LEADERSHIP TEAM</h3> */}
          <div className="row">
            <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/SAL-BC.jpg" />
              <h4>Md. Zulfikar Ali</h4>
              <h6>Chief Financial Officer</h6>
            </div>
            <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/M2.jpeg" />
              <h4>Regwanul Hoque</h4>
              <h6>Company Secretary</h6>
            </div>

            {/*  <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/Md. Nuruzzaman Chowdhury.jpg" />
              <h4>Md. Nuruzzaman Chowdhury FCMA</h4>
              <h6>Nominee Director</h6>
            </div> */}
            <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/GolamMunshi.jpeg" />
              <h4>ABM Golam Mostafa Munshi</h4>
              <h6>Head of Business</h6>
            </div>
            <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/N.H.M Fazle Rabbi.jpg" />
              <h4>N H M Fazle Rabbi</h4>
              <h6>Head of Marketing</h6>
            </div>
            <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/M5.png" />
              <h4>Md. Shakir Mahmud</h4>
              <h6>Head of Production</h6>
            </div>

            {/*  <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/P-30333.jpg" />
              <h4>Shibbir Hossain</h4>
              <h6>Head of Business Development</h6>
            </div> */}
            <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/legalAvd.jpeg" />
              <h4>Md. Niamul Kabir</h4>
              <h6>Legal Advisor</h6>
            </div>
            {/* <div
                            class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15"
                        >
                            <img src="assets/images/CheifIT.png" />
                            <h4>Zahirul Islam</h4>
                            <h6>Chief Information Officer, IT</h6>
                        </div> */}
            {/* <div class="infoContainer col-xl-4 pl-35 pr-54 lg-pr-15 lg-pl-15">
              <img src="assets/images/Mohammad Akhtaruzzaman Khan.jpg" />
              <h4>Mohammad Akhtaruzzaman Khan</h4>
              <h6>Chief Operating Officer</h6>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
