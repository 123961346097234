import React, { useEffect, useState } from "react";
import "./home.css";

import { firebase } from "../firebase";
import { Link, useHistory } from "react-router-dom";

const Header = () => {
  const [isMobileOpen, setisMobileOpen] = useState(false);

  const handleReload = () => {
    setisMobileOpen(true);
  };

  const [financial, setfinancial] = useState();
  const [dividend, setdividend] = useState();
  useEffect(() => {
    firebase
      .database()
      .ref("StarAdhesive/Financial/")
      .on("value", (snapshot) => {
        setfinancial(snapshot.val());
      });
    firebase
      .database()
      .ref("StarAdhesive/Dividend/")
      .on("value", (snapshot) => {
        setdividend(snapshot.val());
      });
  }, []);

  return (
    <header className="">
      <div className="container fluid">
        <nav>
          <div className="logo">
            <img src="assets/images/logo_star_png.png" />
          </div>
          <label
            for="btn"
            className="icon"
            onClick={() => {
              setisMobileOpen(false);
            }}
          >
            <span class="fa fa-bars"></span>
          </label>
          <input type="checkbox" id="btn" />
          <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
            <li>
              <Link to="/" onClick={handleReload}>
                Home
              </Link>
            </li>
            <li>
              <label for="btn-1" class="show">
                Company +
              </label>

              <Link to="#">Company</Link>
              <input type="checkbox" id="btn-1" />
              <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                <li>
                  <Link to="/about" onClick={handleReload}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/Chairman" onClick={handleReload}>
                    Chairman's Message
                  </Link>
                </li>
                <li>
                  <Link to="/MD" onClick={handleReload}>
                    Director's Message
                  </Link>
                </li>

                <li>
                  <Link to="/underConstruction" onClick={handleReload}>
                    Vision & Mission
                  </Link>
                </li>
                <li>
                  <Link to="/Bod" onClick={handleReload}>
                    Board of Directors
                  </Link>
                </li>
                <li>
                  <Link to="/management" onClick={handleReload}>
                    Management Team
                  </Link>
                </li>
                <li>
                  <Link to="/underConstruction" onClick={handleReload}>
                    Corporate Social Responsibilities
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <label for="btn-2" class="show">
                Investors +
              </label>

              <Link to="#">Investors</Link>
              <input type="checkbox" id="btn-2" />
              <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                <li>
                  <Link to="/PriceSensitiveInformation">
                    Price Sensitive Information
                  </Link>
                </li>

                <li>
                  <label for="btn-3" class="show investor">
                    Financial Report +
                  </label>

                  <Link to="/AnnualReport">Financial Report</Link>
                  <input type="checkbox" id="btn-3" />
                  {/* <ul className={isMobileOpen ? "hideMenu" : ""}>
                    <li>
                      <Link to="/AnnualReport">Annual Report</Link>
                    </li>
                    <li>
                      <Link to="/FinancialReport">Financial Statement</Link>
                    </li>
                  </ul> */}
                </li>

                <li>
                  <label for="btn-3" class="show investor">
                    Share Information +
                  </label>

                  <Link to="/SharedInformation">Share Information</Link>
                  <input type="checkbox" id="btn-3" />
                  {/*  <ul className={isMobileOpen ? "hideMenu" : ""}>
                    <li>
                      <a
                        href={url ? url.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Vetted Prospectus
                      </a>
                    </li>
                    <li>
                      <a
                        href={notice ? notice.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Notice for QIs
                      </a>
                    </li>
                    <li>
                      <a
                        href={result ? result.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Allotment Result for SAL
                      </a>
                    </li>
                  </ul> */}
                </li>

                <li>
                  <label for="btn-3" class="show investor">
                    Notice +
                  </label>

                  <Link to="/AllNotice">Notice</Link>
                  <input type="checkbox" id="btn-3" />
                  {/* <ul className={isMobileOpen ? "hideMenu" : ""}>
                    <li style={{ top: "-56px", width: "250px" }}>
                      <Link
                        to="/AllNotice"
                        style={{ padding: "2px", lineHeight: "1" }}
                      >
                        Notice of the 10th Annual General Meeting
                      </Link>
                    </li>
                  </ul> */}
                </li>

                <li>
                  <Link to="/BondIssue">Bond Issue</Link>
                </li>

                {/*  <li>
                  <Link to="/underConstruction" onClick={handleReload}>
                    Corporate Corner
                  </Link>
                </li> */}

                {/* <li>
                  <label for="btn-3" class="show investor">
                    Investor Center +
                  </label>

                  <Link to="#">Investor Center</Link>
                  <input type="checkbox" id="btn-3" />
                  <ul className={isMobileOpen ? "hideMenu" : ""}>
                    <li>
                      <a
                        href={url ? url.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Vetted Prospectus
                      </a>
                    </li>
                    <li>
                      <a
                        href={notice ? notice.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Notice for QIs
                      </a>
                    </li>
                    <li>
                      <a
                        href={result ? result.DownloadUrl : "#"}
                        target="_blank"
                        download
                      >
                        Allotment Result for SAL
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </li>
            <li>
              <label for="btn-9" class="show">
                Products +
              </label>

              <Link to="#">Products</Link>
              <input type="checkbox" id="btn-9" />
              <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                <li>
                  <label for="btn-4" class="show">
                    STAR BOND +
                  </label>

                  <Link to="">STAR BOND</Link>
                  <input type="checkbox" id="btn-4" />
                  <ul className={isMobileOpen ? "hideMenu" : ""}>
                    <li>
                      <Link to="/SyntheticResinAdhesive" onClick={handleReload}>
                        Star Bond Synthetic Resin Adhesive
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/SyntheticRubberAdhesive"
                        onClick={handleReload}
                      >
                        Star Bond Synthetic Rubber Adhesive
                      </Link>
                    </li>
                    <li>
                      <Link to="/FixGelTube" onClick={handleReload}>
                        Star Bond Fix Gel Tube
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/SyntheticDistemberAdhesives"
                        onClick={handleReload}
                      >
                        Synthetic Distemper Adhesive
                      </Link>
                    </li>
                    <li>
                      <Link to="/SuperGlue" onClick={handleReload}>
                        Star Bond Super Glue
                      </Link>
                    </li>
                    <li>
                      <Link to="/PurAdhesives" onClick={handleReload}>
                        Star Bond Pur Adhesives
                      </Link>
                    </li>
                    <li>
                      <Link to="/StarBondSB40" onClick={handleReload}>
                        Star Bond SB 40
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/StarBondDashboardCleaner"
                        onClick={handleReload}
                      >
                        Star Bond Dashboard Cleaner
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/StarBondHotMeltAdhesives"
                        onClick={handleReload}
                      >
                        Star Bond Hot Melt Adhesives
                      </Link>
                    </li>

                    <li>
                      <Link to="/StarBondNCThinner" onClick={handleReload}>
                        Star Bond NC Thinner
                      </Link>
                    </li>
                    <li>
                      <Link to="/StarBondSilica" onClick={handleReload}>
                        Star Bond Silica
                      </Link>
                    </li>
                    <li>
                      <Link to="/UPVCSolventCement" onClick={handleReload}>
                        uPVC Solvent Cement
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/StarBondWoodPreservative"
                        onClick={handleReload}
                      >
                        Wood Preservative
                      </Link>
                    </li>
                    <li>
                      <Link to="/StarBondSprayPaint" onClick={handleReload}>
                        Spray Paint
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <label for="btn-5" class="show">
                    STARLAQ +
                  </label>

                  <Link to="">STARLAQ</Link>
                  <input type="checkbox" id="btn-5" />
                  <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                    <li>
                      <Link to="/PuHardener" onClick={handleReload}>
                        STARLAQ PU Hardener
                      </Link>
                    </li>

                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        STARLAQ PU Top Coat
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        STARLAQ Wood Putty
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        STARLAQ Wood Filler
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        STARLAQ Stain
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <label for="btn-6" class="show">
                    GUMKING +
                  </label>

                  <Link to="">GUMKING</Link>
                  <input type="checkbox" id="btn-6" />
                  <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                    <li>
                      <Link to="/GumkingSyntheticResin" onClick={handleReload}>
                        Gumking Synthetic Resin Adhesive
                      </Link>
                    </li>
                    <li>
                      <Link to="/GumkingSyntheticRubber" onClick={handleReload}>
                        Gumking Synthetic Rubber Adhesive
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Grease
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Spray Paint
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <label for="btn-7" class="show">
                    STAR KING +
                  </label>

                  <Link to="">STAR KING</Link>
                  <input type="checkbox" id="btn-7" />
                  <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Tiles Cleaner
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Floor Cleaner
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Glass Cleaner
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <label for="btn-8" class="show">
                    CYCLON +
                  </label>

                  <Link to="">CYCLON</Link>
                  <input type="checkbox" id="btn-8" />
                  <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Lubricant
                      </Link>
                    </li>
                    <li>
                      <Link to="/underConstruction" onClick={handleReload}>
                        Grease
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <label for="btn-11" class="show">
                Media +
              </label>
              <Link to="#">Media</Link>
              <input type="checkbox" id="btn-11" />
              <ul className={isMobileOpen ? "hideMenu" : "showMenu"}>
                <li>
                  <a
                    //href={notice ? notice.DownloadUrl : "#"}
                    href="#"
                    /* target="_blank"
                    download */
                  >
                    News
                  </a>
                </li>
                <li>
                  <Link to="/underConstruction" onClick={handleReload}>
                    Event
                  </Link>
                </li>
                <li>
                  <Link to="/underConstruction" onClick={handleReload}>
                    Gallery
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/underConstruction" onClick={handleReload}>
                Career
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleReload}>
                Contact
              </Link>
            </li>

            {/*  Only for SubDomain */}
            {/* <li>
              <Link
                className="subDomainUpload"
                to="/UploadFiles"
                onClick={handleReload}
              >
                Upload
              </Link>
            </li> */}
            {/*  Only for SubDomain */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
