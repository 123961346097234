import React, { useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import Form from "react-bootstrap/Form";

import "./Product.css";

const GumkingSyntheticRubber = () => {
  const [show50, setShow50] = useState(true);
  const [show5, setShow5] = useState(false);
  const [show20, setShow20] = useState(false);
  const [show, setShow] = useState(false);

  const [imageLink, setimageLink] = useState(
    "assets/products/Gumking/Rubber/1.png"
  );

  const [Boisistho, setBoisistho] = useState(true);
  const [sotorkota, setsotorkota] = useState(false);

  var btns = document.getElementsByClassName("btn2");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function () {
      var current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active");
      this.className += " active";
    });
  }

  const changeDropdown = (value) => {
    if (value == "50g") {
      setShow50(true);
      setShow5(false);
      setShow20(false);
      setimageLink("assets/products/Gumking/Rubber/1.png");
    } else if (value == "125g") {
      setShow50(false);
      setShow5(true);
      setShow20(false);
      setimageLink("assets/products/Gumking/Rubber/2.png");
    }
  };
  return (
    <>
      <Container className="productDetailContainer">
        <Row>
          <Col className="ProductImageContainer">
            <h4>Gumking Synthetic Rubber Adhesives</h4>
            {show50 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/1.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/1.png");
                    }}
                  />

                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/2.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/2.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/3.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/3.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/4.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/4.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/5.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/5.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/6.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/6.png");
                    }}
                  />
                  <img
                    className=""
                    src="assets/products/Gumking/Rubber/7.png"
                    onClick={() => {
                      setimageLink("assets/products/Gumking/Rubber/7.png");
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show5 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: imageLink,
                      },
                      largeImage: {
                        src: imageLink,
                        width: 960,
                        height: 740,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                        height: "100%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/5 kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img
                    className=""
                    src="assets/images/product/Gumking/Rubber/1.png"
                    onClick={() => {
                      setimageLink(
                        "assets/images/product/Gumking/Rubber/1.png"
                      );
                    }}
                  />

                  <img
                    className=""
                    src="assets/images/product/Gumking/Rubber/2.png"
                    onClick={() => {
                      setimageLink(
                        "assets/images/product/Gumking/Rubber/2.png"
                      );
                    }}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            {show20 ? (
              <>
                <div className="mainImageContainer">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: "assets/images/product/10 Kg.png",
                      },
                      largeImage: {
                        src: "assets/images/product/10 Kg.png",
                        width: 960,
                      },
                      enlargedImageContainerDimensions: {
                        width: "200%",
                      },
                    }}
                    imageClassName="main_Image2"
                  />
                  {/* <img className='main_Image2' src="assets/images/product/10 Kg.png" /> */}
                </div>
                <div className="thumb-images">
                  <img className="" src="assets/images/product/B-58.png" />
                  <img
                    className=""
                    src="assets/images/product/20kg-large.png"
                  />
                  <img
                    className=""
                    src="assets/images/product/10 kg-large.png"
                  />
                </div>
              </>
            ) : (
              " "
            )}
          </Col>
          <Col>
            <div className="productInfo">
              <h3>
                <b>USES</b>
              </h3>
              <p>
                Suitable for leather products, shoe industry, rexine, foam,
                aging of furniture, formica etc. Shake well before using in shoe
                industry or furniture. Use Gumking Synthetic Rubber Adhesives to
                clean surfaces. After drying well, press both sides together.
              </p>

              <h3>
                <b>SIZE</b>
              </h3>
              <Form.Select
                size="lg"
                id="choices"
                onChange={(e) => {
                  changeDropdown(e.target.value);
                }}
              >
                <option value="50g">100 ml</option>
                <option value="125g">200 ml</option>
                <option value="125g">500 ml</option>
                <option value="50g">1 Ltr</option>
                <option value="125g">2 Ltr</option>
                <option value="125g">5 Ltr</option>
                <option value="125g">10 Ltr</option>
                <option value="125g">15 Ltr</option>
              </Form.Select>
              {/* <div className='sizes'>
                                        <button className='' onClick={() => { setShow50(true); setShow5(false); setShow20(false); setimageLink("assets/images/product/B-58.png") }}>50 g</button>
                                        <button className='' onClick={() => { setShow50(false); setShow5(true); setShow20(false); setimageLink("assets/images/product/125 G-large.png") }}>125 g</button>
                                        <button className='' onClick={() => { setShow50(false); setShow5(false); setShow20(true) }}>250 g</button>
                                        <button className=''>500 g</button>
        
                                    </div>
                                    <div className='sizes'>
                                        <button className='' onClick={() => { setShow50(true); setShow5(false); setShow20(false) }}>1 kg</button>
                                        <button className='' onClick={() => { setShow50(false); setShow5(true); setShow20(false) }}>2 kg</button>
                                        <button className='' onClick={() => { setShow50(false); setShow5(false); setShow20(true) }}>5 kg</button>
                                        <button className=''>10 kg</button>
        
                                    </div>
                                    <div className='sizes'>
                                        <button className='' onClick={() => { setShow50(true); setShow5(false); setShow20(false) }}>20 kg</button>
                                        <button className='' onClick={() => { setShow50(false); setShow5(true); setShow20(false) }}>40 kg</button>
                                        <button className='' onClick={() => { setShow50(false); setShow5(false); setShow20(true) }}>50 kg</button>
                                        <button className=''>100 kg</button>
                                    </div> */}
            </div>

            <div className="CallSection">
              <p>
                For any quarries or Product related information, please call{" "}
                <a href="tel:+8801313012575">+8801313012575</a>. You can contact
                us through (WhatsApp icon) or (messenger icon) as well.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div>
          <div className="headingSection">
            <p
              className="btn2 active"
              onClick={() => {
                setBoisistho(true);
                setsotorkota(false);
              }}
            >
              Characteristics
            </p>
            <p
              className="btn2"
              onClick={() => {
                setBoisistho(false);
                setsotorkota(true);
              }}
            >
              Caution
            </p>
            <hr />
          </div>

          {Boisistho ? (
            <div className="SectionDescription Boisistho">
              <ul>
                <li>More Coverage</li>
                <li>More adhesive quality</li>
                <li>Syitable for long term storage</li>
                <li>Strong bonding power</li>

                <li>Free from odor and harmful chemicals</li>
              </ul>
            </div>
          ) : (
            " "
          )}
          {sotorkota ? (
            <div className="SectionDescription Sotorkota">
              <ul>
                <li>Keep away from fire</li>
                <li>Keep out of reach of children</li>
                <li>Do not use in closed environment</li>
              </ul>
            </div>
          ) : (
            " "
          )}
        </div>
      </Container>
    </>
  );
};

export default GumkingSyntheticRubber;
